import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from '../../components/header/Header';
import { paths } from '../../router/routes';
import About from '../../view/routes/about/About';
import HomePage from '../../view/routes/home-page/HomePage';
import results from '../../view/routes/results/Results';
import './pages.scss';
import FAQ from '../../view/routes/faq/FAQ';

interface Props extends RouteComponentProps {}

const PagesComponent: FunctionComponent<Props> = ({ location }) => {
  const key = location && location.pathname ? location.pathname : 'first';
  return (
    <div className="pages">
      <Header />
      <TransitionGroup className="pages__container">
        <CSSTransition
          timeout={{ enter: 500, exit: 0 }}
          key={key}
          classNames="pages__page--fade-trans"
        >
          <div className="pages__page" key={key}>
            <Switch location={location}>
              <Route
                path={paths.results}
                exact={true}
                strict={false}
                component={results}
              />
              <Route
                path={paths.home}
                exact={true}
                strict={false}
                component={HomePage}
              />
              <Route
                path={paths.about}
                exact={true}
                strict={false}
                component={About}
              />
              <Route
                path={paths.faq}
                exact={true}
                strict={false}
                component={FAQ}
              />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default withRouter(PagesComponent);
