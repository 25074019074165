import Lottie from 'lottie-web';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import './LottieAnimation.scss';

enum Direction {
  FORWARD = 'forward',
  REVERSED = 'reversed'
}

const animationDirection = direction => {
  switch (direction) {
    case Direction.REVERSED:
      return -1;
    case Direction.FORWARD:
    default:
      return 1;
  }
};

interface Props {
  animationData: object;
  width?: number | string;
  height?: number | string;
  speed?: number;
  direction?: Direction;
  loop?: boolean;
  autoplay?: boolean;
}

const LottieAnimation: FunctionComponent<Props> = ({
  animationData,
  width = '100%',
  height = '100%',
  speed = 1,
  autoplay = true,
  loop = true,
  direction = 'forward'
}) => {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (ref.current) {
      const animation = Lottie.loadAnimation({
        container: ref.current, // the dom element
        renderer: 'svg',
        loop,
        autoplay,
        animationData, // the animation data
        rendererSettings: {
          // scaleMode: 'noScale',
          progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          hideOnTransparent: true //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
        }
      });
      animation.setSpeed(speed);
      animation.setDirection(animationDirection(direction));
    }
  }, [ref, animationData, speed, direction, loop, autoplay]);
  return (
    <span
      ref={ref}
      style={{ width, height }}
      className="lottie-animation"
    ></span>
  );
};

/* AcLottieAnimation.propTypes = {
  animationData: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  speed: PropTypes.number,
  direction: PropTypes.oneOf([Direction.FORWARD, Direction.REVERSED]),
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
}; */

export default LottieAnimation;
