import clsx from 'clsx';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { paths } from '../../router/routes';
import { StoreState, history } from '../../store';
import { updateCurrency } from '../../store/values/valueActions';
import { Currency } from '../../utils/constants';
import SelectBox, { SelectOption } from '../select-box/SelectBox';
import './Header.scss';

interface Props {
  className?: string;
}

const options: SelectOption[] = [
  { value: Currency.EURO, label: 'EUR €' },
  { value: Currency.US_DOLLAR, label: 'USD $' },
  { value: Currency.POUNDS, label: 'GBP £' }
];

const Header: FunctionComponent<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const currency = useSelector<StoreState, string>(
    state => state.values.currency
  );
  const location = useLocation();
  const isHome = useMemo(
    () =>
      matchPath(location.pathname, {
        path: paths.home,
        exact: true,
        strict: true
      }) !== null,
    [location]
  );
  const classes = clsx('header', className, {
    'header--home-route': isHome
  });
  const onChange = useCallback(
    (event: any) => {
      dispatch(updateCurrency(event.target.value));
    },
    [dispatch]
  );
  const logoClick = useCallback(() => {
    history.push(paths.home);
  }, []);
  return (
    <div className={classes}>
      <div className="header__logo" onClick={logoClick}></div>
      <div className="header__navigation">
        <NavLink to={paths.home} className="header__nav-link">
          Home
        </NavLink>
        <NavLink to={paths.about} className="header__nav-link">
          About
        </NavLink>
        <NavLink to={paths.faq} className="header__nav-link">
          FAQ
        </NavLink>
        <SelectBox
          className="header__nav-currency"
          transparent={isHome}
          id="currency"
          name="currency"
          options={options}
          onChange={onChange}
          value={currency}
        ></SelectBox>
      </div>
    </div>
  );
};

export default Header;
