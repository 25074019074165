import React, { ReactNode, FunctionComponent } from 'react';
import clsx from 'clsx';
import './ResponsiveContainer.scss';
interface Props {
  children: ReactNode;
  className?: string;
}
const ResponsiveContainer: FunctionComponent<Props> = ({
  children,
  className
}) => {
  return (
    <div className={clsx('responsive-container', className)}>
      <div className="responsive-container__content">{children}</div>
    </div>
  );
};

export default ResponsiveContainer;
