import clsx from 'clsx';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  HTMLProps,
  KeyboardEvent,
  memo,
  useState
} from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import ErrorMessage from '../error-message/ErrorMessage';
import './input.scss';

export interface InputProps {
  id: string;
  name?: string;
  type?: 'text' | 'password';
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value?: string;
  fullWidth?: boolean;
  error?: string;
  touched?: boolean;
  noAutoComplete?: boolean;
  hideErrorMessage?: boolean;
  inputElement?: 'input' | typeof MaskedInput;
  maskedInputProps?: MaskedInputProps;
  inputProps?: HTMLProps<HTMLInputElement>;
  helperText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

const Input = forwardRef<any, InputProps>(
  (
    {
      id,
      name,
      placeholder,
      type = 'text',
      className = '',
      disabled = false,
      fullWidth = false,
      error = undefined,
      value = '',
      onChange,
      helperText,
      hideErrorMessage = false,
      noAutoComplete = false,
      touched,
      inputElement: InputElement = 'input',
      maskedInputProps,
      inputProps,
      onBlur = () => {},
      onKeyPress = () => {}
    },
    ref
  ) => {
    const [focused, setFocused] = useState<boolean>(false);

    const hasError = touched && error !== undefined;

    const classes = clsx('input', className, {
      'input--disabled': disabled,
      'input--error': hasError,
      'input--placeholder': placeholder && placeholder !== '',
      'input--full-width': fullWidth,
      'input--focused': focused || !!value
    });

    let inputClasses = 'input__field';
    if (inputProps) {
      const { className: inputClassName, ...inputProps2 } = inputProps;
      inputClasses = clsx(inputClasses, inputClassName);
      inputProps = inputProps2;
    }
    return (
      <span className={classes}>
        <InputElement
          ref={ref}
          {...maskedInputProps}
          id={id}
          name={name}
          type={type}
          className={inputClasses}
          disabled={disabled}
          onChange={onChange}
          autoComplete={noAutoComplete ? 'off' : 'on'}
          onFocus={() => setFocused(true)}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            setFocused(false);
            onBlur(event);
          }}
          onKeyPress={onKeyPress}
          {...inputProps}
          value={value}
        />
        {placeholder && (
          <span className="input__placeholder">{placeholder}</span>
        )}
        {!hideErrorMessage && hasError && (
          <ErrorMessage withIcon>{error}</ErrorMessage>
        )}
        {(hideErrorMessage || !hasError) && helperText && (
          <span className="input__helper-text">{helperText}</span>
        )}
      </span>
    );
  }
);

export default memo(Input);
