import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import animationData from '../../../assets/animations/flying-paper-plane.json';
import GoogleMap from '../../../components/google-map';
import Plane from '../../../components/icons/Plane';
import LottieAnimation from '../../../components/lottie-animation/LottieAnimation';
import Page from '../../../components/page/page';
import SearchBar from '../../../components/search-bar/SearchBar';
import Typography from '../../../components/typography/Typography';
import { StoreState } from '../../../store';
import { fetchStoredSearch } from '../../../store/destinations/destinationActions';
import {
  FlightResults,
  SearchData
} from '../../../store/destinations/destinationReducer';
import FlightList from './flight-list/FlightList';
import './Results.scss';

interface Props {}

const Results: FunctionComponent<Props> = () => {
  const dispatch = useDispatch();
  const results = useSelector<StoreState, FlightResults | undefined>(
    state => state.destination.results
  );
  const search = useSelector<StoreState, SearchData | undefined>(
    state => state.destination.search
  );
  const fetching = useSelector<StoreState, boolean>(
    state => state.destination.fetching
  );
  // const currency = useSelector<StoreState, Currency>(state => state.values.currency);
  const [selected, setSelected] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchStoredSearch());
  }, [dispatch]);
  const flight =
    results && results.flights && selected
      ? results.flights.find(({ id }) => selected === id)
      : undefined;

  const resultComps = useMemo(() => {
    if (!fetching) {
      if (results && results.flights) {
        return (
          <FlightList
            className="results__destinations-list"
            results={results}
            selected={selected}
            onSelected={selected => setSelected(selected)}
          />
        );
      } else if (search !== undefined) {
        return (
          <div className="results__no-flights">
            <Plane />
            <Typography type="h2" variant="secondary">
              No Flights Found
            </Typography>
            <Typography type="body" variant="secondary">
              Try different dates
            </Typography>
          </div>
        );
      }
    }
    return (
      <div className="results__no-flights">
        <Plane />
        <Typography type="h2" variant="secondary">
          Choose your Origins and Dates
        </Typography>
        <Typography type="body" variant="secondary">
          Find your departure airports
        </Typography>
        <Typography type="body" variant="secondary">
          Choose your flight dates
        </Typography>
      </div>
    );
  }, [fetching, results, search, selected]);
  return (
    <Page className="results">
      <div className="results__destinations">
        <div className="results__destinations-header">
          <SearchBar fetchOnChange />
        </div>
        <div className="results__destinations-list-container">
          <SwitchTransition>
            {!fetching ? (
              <CSSTransition
                key="results"
                timeout={{ enter: 200, exit: 150 }}
                classNames="results__destinations-list--trans"
              >
                {resultComps}
              </CSSTransition>
            ) : (
              <CSSTransition
                key="loader"
                timeout={{ enter: 200, exit: 150 }}
                classNames="results__destinations-list--trans"
              >
                <LottieAnimation
                  animationData={animationData}
                  width="100%"
                  height="auto"
                />
              </CSSTransition>
            )}
          </SwitchTransition>
        </div>
      </div>
      <div className="results__map">
        <GoogleMap
          className="results__google-maps"
          results={results}
          selected={flight}
          onMarker={selected => setSelected(selected)}
        />
      </div>
    </Page>
  );
};

export default Results;
