import clsx from 'clsx';
import React, { FunctionComponent, memo } from 'react';
import ButtonWithIcon, { ButtonWithIconProps } from './button-with-icon';
import './primary-button.scss';

const PrimaryButton: FunctionComponent<ButtonWithIconProps> = ({
  children,
  className,
  ...props
}) => (
  <ButtonWithIcon
    className={clsx(className, 'button--variant-primary')}
    {...props}
  >
    {children}
  </ButtonWithIcon>
);

export default memo(PrimaryButton);
