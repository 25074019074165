import React, { FunctionComponent, useMemo } from 'react';
import './CollapsibleHeader.scss';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../../store';
import { Currency, CurrencySymbol } from '../../../../../utils/constants';

interface Props {
  country: string;
  city: string;
  code: string;
  combinedPrice: string;
}

interface CurrencyMap {
  [key: string]: string;
}

const currencyMap: CurrencyMap = {
  [Currency.EURO]: CurrencySymbol.EURO,
  [Currency.POUNDS]: CurrencySymbol.POUNDS,
  [Currency.US_DOLLAR]: CurrencySymbol.US_DOLLAR
};

const CollapsibleHeader: FunctionComponent<Props> = ({
  country,
  city,
  code,
  combinedPrice
}) => {
  const currency = useSelector<StoreState, Currency>(
    state => state.values.currency
  );
  return useMemo(
    () => (
      <div className="collapsible-header">
        <div className="collapsible-header__column">
          <span className="collapsible-header__city-label">{country}</span>
          <div className="collapsible-header__row">
            <span className="collapsible-header__city">{city}</span>
            <span className="collapsible-header__aiport">{code}</span>
          </div>
        </div>
        <div className="collapsible-header__column">
          <span className="collapsible-header__price-label">
            Combined Price
          </span>
          <span className="collapsible-header__price">
            {currencyMap[currency]} {combinedPrice}
          </span>
        </div>
      </div>
    ),
    [country, city, code, combinedPrice, currency]
  );
};
export default CollapsibleHeader;
