import clsx from 'clsx';
import React, {
  ElementType,
  FunctionComponent,
  memo,
  MouseEvent,
  ReactNode
} from 'react';
import './button.scss';

export enum ButtonIconAligment {
  RIGHT = 'right',
  LEFT = 'left'
}

export interface IconProps {
  align: ButtonIconAligment;
  component: ReactNode | string;
}

export interface ButtonProps {
  id?: string;
  children: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  className?: string;
  disabled?: boolean;
  small?: boolean;
  centerChildren?: boolean;
  component?: ElementType;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const Button: FunctionComponent<ButtonProps> = ({
  id,
  children,
  onClick = undefined,
  className = '',
  disabled = false,
  small = false,
  centerChildren = false,

  type = 'button',
  component: Component = 'button',
  ...props
}) => {
  let classes = clsx('button', className, {
    'button--disabled': disabled,
    'button--small': small,
    'button--center-children': centerChildren
  });

  if (Component === 'button') {
    props = { ...props, type };
  }
  if (onClick) {
    props = { ...props, onClick };
  }
  return (
    <Component id={id} className={classes} {...props}>
      {children}
    </Component>
  );
};

export default memo(Button);
