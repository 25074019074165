import { SearchOrigin } from '../../model/destination';
import { Airport, Flight } from '../../model/flight';
import {
  DestinationsActions,
  DestinationsActionType
} from './destinationsActionTypes';

interface LatLng {
  lat: string;
  lng: string;
}

interface Origin {
  id: string;
  label: string;
  position: LatLng;
}
export interface FlightResults {
  flights: Flight[];
  origins: Origin[];
}

export interface SearchData {
  origin1: SearchOrigin;
  origin2: SearchOrigin;
  dates: {
    start?: string;
    end?: string;
  };
}

export interface DestinationsState {
  results?: FlightResults;
  airports?: Airport[];
  search?: SearchData;
  fetching: boolean;
  errorKey?: string;
}
const initialState: DestinationsState = {
  results: undefined,
  airports: undefined,
  fetching: false,
  errorKey: undefined
};

const reducers = (
  state: DestinationsState = initialState,
  action: DestinationsActions
): DestinationsState => {
  switch (action.type) {
    case DestinationsActionType.fetch:
      return {
        ...state,
        fetching: true,
        results: undefined,
        search: {
          ...action.payload,
          dates: {
            start: action.payload.dates.start!.format('DD/MM/YYYY'),
            end: action.payload.dates.end!.format('DD/MM/YYYY')
          }
        }
      };
    case DestinationsActionType.received:
      const { results, airports } = action.payload.data;
      return {
        ...state,
        fetching: false,
        results,
        airports
      };
    case DestinationsActionType.failed:
      const { errorKey } = action.payload;
      return {
        ...state,
        fetching: false,
        errorKey
      };
    default:
      return state;
  }
};
export default reducers;
