import React, { FocusEvent, forwardRef } from 'react';
import SelectBoxNative from './SelectBoxNative';
import SelectBoxNonNative from './SelectBoxNonNative';

export interface SelectOption {
  value: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  hidden?: boolean;
}
export interface SelectProps {
  id: string;
  name: string;
  transparent?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  onChange: (event: any) => void;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
  options: SelectOption[];
}
interface Props extends SelectProps {
  native?: boolean;
}
const SelectBox = forwardRef<any, Props>(
  ({ native = false, ...props }, ref) => {
    return native ? (
      <SelectBoxNative {...props} ref={ref} />
    ) : (
      <SelectBoxNonNative {...props} ref={ref} />
    );
  }
);

export default SelectBox;
