import clsx from 'clsx';
import React, { FunctionComponent, memo, ReactNode } from 'react';
import Error from '../icons/Error';
import Icon from '../icon/Icon';
import './ErrorMessage.scss';

interface Props {
  children: ReactNode;
  id?: string;
  className?: string;
  withIcon?: boolean;
  noMargin?: boolean;
}
const ErrorMessage: FunctionComponent<Props> = ({
  children,
  id = undefined,
  className = '',
  withIcon = false,
  noMargin = false
}) => {
  return (
    <span
      id={id}
      className={clsx('error-message', className, {
        'error-message--no-margin': noMargin
      })}
    >
      {withIcon && (
        <>
          <Icon variant="error" className="error-message__icon">
            <Error />
          </Icon>{' '}
        </>
      )}
      {children}
    </span>
  );
};

export default memo(ErrorMessage);
