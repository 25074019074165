import React, { FunctionComponent } from 'react';
import SearchBar from '../../../components/search-bar/SearchBar';
import Typography from '../../../components/typography/Typography';
import { paths } from '../../../router/routes';
import { history } from '../../../store';
import './HomePage.scss';
import Plane from '../../../components/icons/Plane';
import MapImage from '../../../assets/images/home-map.jpg';
import ResultsImage from '../../../assets/images/home-results.jpg';
import MousePointerSolid from '../../../components/icons/MousePointerSolid';
import CompassRegular from '../../../components/icons/CompassRegular';
import CalendarAltRegular from '../../../components/icons/CalendarAltRegular';
interface Props {}
const HomePage: FunctionComponent<Props> = () => {
  return (
    <div className="home-page">
      <div className="home-page__header-container">
        <div className="home-page__header-content">
          <Typography
            type="none"
            className="home-page__main-title"
            variant="primary"
            lineHeight="sm"
            marginBottom="sm"
          >
            Why not meet <br />
            in the middle?
          </Typography>
          <Typography
            type="none"
            className="home-page__sub-title"
            variant="primary"
          >
            Fill in both origins <br />
            to find a destination
          </Typography>
        </div>
      </div>
      <div className="home-page__search">
        <SearchBar
          className="home-page__search-bar"
          onSubmit={() => history.push(paths.results)}
        />
      </div>
      <div className="home-page__explanation-search">
        <img
          src={MapImage}
          alt="map"
          className="home-page__explanation-search-image"
        />
        <div className="home-page__explanation-points">
          <Typography
            type="h3"
            variant="secondary"
            className="home-page__explanation-title"
          >
            How it Works?
          </Typography>
          <Typography
            type="body-large"
            lineHeight="lg"
            variant="secondary"
            className="home-page__explanation-point"
          >
            Enter two origins, yours, and the origin of your friend(s) or
            relative(s)
          </Typography>
          <MousePointerSolid className="home-page__explanation-icon" />
          <CalendarAltRegular className="home-page__explanation-icon" />
          <Typography
            type="body-large"
            lineHeight="lg"
            variant="secondary"
            className="home-page__explanation-point"
          >
            Adjust the departure and return data for your trip to your
            preference
          </Typography>
          <Typography
            type="body-large"
            lineHeight="lg"
            variant="secondary"
            className="home-page__explanation-point"
          >
            Fill in a destination of your choice. 'Anywhere' let's you explore
            destinations
          </Typography>
          <CompassRegular className="home-page__explanation-icon" />
        </div>
      </div>
      <div className="home-page__explanation-results">
        <div className="home-page__explanation-results-left">
          <span>
            <Typography type="body-large" lineHeight="lg" variant="secondary">
              your destination
            </Typography>
          </span>
          <span>
            <Typography type="body-large" lineHeight="lg" variant="inherit">
              your ticket
            </Typography>
          </span>
          <span></span>
        </div>
        <img
          src={ResultsImage}
          alt="results"
          className="home-page__explanation-results-image"
        />
        <div className="home-page__explanation-results-right">
          <span>
            <Typography type="body-large" lineHeight="lg" variant="secondary">
              combined price
            </Typography>
          </span>
          <span>
            <Typography type="body-large" lineHeight="lg" variant="secondary">
              individual ticket price
            </Typography>
          </span>
          <span>
            <Typography type="body-large" lineHeight="lg" variant="inherit">
              their ticket
            </Typography>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
