import React, { FunctionComponent, ReactNode, useContext } from 'react';
import clsx from 'clsx';

import { ListContext } from './list';

import './list-item.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const ListItem: FunctionComponent<Props> = ({ children, className = '' }) => {
  const { childSpacing, variant, listType, lineHeight } = useContext(
    ListContext
  );

  const classes = clsx('list-item', className, {
    'list-item--large-spacing': childSpacing === 'large',
    'list-item--small-spacing': childSpacing === 'small',
    'list-item--variant-primary': variant === 'primary',
    'list-item--variant-secondary': variant === 'secondary',
    'list-item--list-type-check': listType === 'check',
    'list-item--line-height-large': lineHeight === 'large',
    'list-item--line-height-small': lineHeight === 'small'
  });

  return <li className={classes}>{children}</li>;
};

export default ListItem;
