import clsx from 'clsx';
import React, {
  FunctionComponent,
  memo,
  ReactElement,
  cloneElement
} from 'react';
import Button, { ButtonProps } from './Button';
import './icon-button.scss';

export interface IconButtonProps extends ButtonProps {
  noPadding?: boolean;
  children: ReactElement;
}

const IconButton: FunctionComponent<IconButtonProps> = ({
  children,
  className,
  noPadding = false,
  ...props
}) => {
  const Icon = cloneElement(children, { className: 'button__icon-child' });
  return (
    <Button
      className={clsx('button--variant-icon', className, {
        'button--no-padding': noPadding
      })}
      {...props}
    >
      {Icon}
    </Button>
  );
};

export default memo(IconButton);
