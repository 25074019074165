import clsx from 'clsx';
import React, { FunctionComponent, memo, ReactNode, ReactType } from 'react';
import './Typography.scss';

interface Props {
  children: ReactNode;
  className?: string;
  type?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body'
    | 'body-large'
    | 'body-small'
    | 'body-small-light'
    | 'none';
  variant?: 'primary' | 'secondary' | 'inherit';
  lineHeight?: 'xs' | 'sm' | 'lg' | 'normal';
  marginBottom?: 'none' | 'xs' | 'sm' | 'lg';
  marginTop?: 'none' | 'xs' | 'sm' | 'lg';
}
const defaultTypeMapping: { [key: string]: ReactType } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  body: 'p',
  'body-large': 'p',
  'body-small': 'p',
  'body-small-light': 'p',
  none: 'p'
};
const Typography: FunctionComponent<Props> = ({
  children,
  lineHeight = 'normal',
  marginBottom = 'none',
  marginTop = 'none',
  type = 'body',
  variant,
  className = ''
}) => {
  const classes = clsx(className, 'typography', `typography--${type}`, {
    [`typography--variant-${variant}`]: variant,
    'typography--line-height-lg': lineHeight === 'lg',
    'typography--line-height-sm': lineHeight === 'sm',
    'typography--line-height-xs': lineHeight === 'xs',
    'typography--margin-bottom-lg': marginBottom === 'lg',
    'typography--margin-bottom-sm': marginBottom === 'sm',
    'typography--margin-bottom-xs': marginBottom === 'xs',
    'typography--margin-top-lg': marginTop === 'lg',
    'typography--margin-top-sm': marginTop === 'sm',
    'typography--margin-top-xs': marginTop === 'xs'
  });
  const Component = defaultTypeMapping[type];
  return <Component className={classes}>{children}</Component>;
};

export default memo(Typography);
