import clsx from 'clsx';
import { useSelect } from 'downshift';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';
import { SelectOption, SelectProps } from './SelectBox';
import './SelectBoxNonNative.scss';

const INTERSECT_THRESHOLD: number[] = [
  0.1,
  0.2,
  0.3,
  0.4,
  0.5,
  0.6,
  0.7,
  0.8,
  0.9,
  1
];

const SelectBoxNonNative = forwardRef<HTMLSpanElement, SelectProps>(
  (props, ref) => {
    const {
      className,
      options,
      placeholder,
      id,
      name,
      value,
      disabled = false,
      transparent = false,
      onChange = (event: any) => {},
      onBlur = () => {}
    } = props;

    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      setHighlightedIndex
    } = useSelect<SelectOption>({
      items: options,
      id,
      initialSelectedItem: options.reduce<SelectOption | undefined>(
        (acc, option) => (value === option.value ? option : acc),
        undefined
      )
    });
    const buttonRef = useShareForwardedRef<HTMLSpanElement>(ref);

    const [listIntersect, listIntersectApi] = useIntersectionObserver(
      undefined,
      '0px',
      INTERSECT_THRESHOLD
    );

    const [openPosition, updateOpenPosition] = useState<'bottom' | 'top'>(
      'bottom'
    );
    const [leftListOffset, updateLeftListOffset] = useState<number | undefined>(
      undefined
    );

    const onOpening = useCallback(() => {
      if (listIntersect && buttonRef.current) {
        const {
          intersectionRatio,
          boundingClientRect,
          intersectionRect,
          isIntersecting
        } = listIntersect;
        const offset =
          (buttonRef.current.clientWidth - boundingClientRect.width) / 2;
        updateLeftListOffset(offset);
        if (
          intersectionRatio < 1 &&
          intersectionRatio !== 0 &&
          isIntersecting
        ) {
          if (
            openPosition === 'bottom' &&
            boundingClientRect.height > intersectionRect.height &&
            boundingClientRect.top > 0
          ) {
            updateOpenPosition('top');
          } else if (openPosition === 'top' && boundingClientRect.y < 0) {
            updateOpenPosition('bottom');
          }
        }
      }
    }, [listIntersect, openPosition, buttonRef]);

    const onClosed = useCallback(() => {
      updateLeftListOffset(undefined);
      updateOpenPosition('bottom');
      listIntersectApi.disconnect();
    }, [updateLeftListOffset, updateOpenPosition, listIntersectApi]);

    useEffect(() => {
      onOpening();
    }, [listIntersect, onOpening]);
    const setListRef = useCallback(
      (node: HTMLUListElement) => {
        listIntersectApi.registerNode(node);
      },
      [listIntersectApi]
    );

    const classes = clsx('ac-select-box-non-native', className, {
      'ac-select-box-non-native--has-selection': value !== undefined,
      'ac-select-box-non-native--transparent': transparent,
      'ac-select-box-non-native--is-open': isOpen,
      'ac-select-box-non-native--disabled': disabled,
      'ac-select-box-non-native--open-top': openPosition === 'top'
    });

    return (
      <span className={classes} ref={buttonRef}>
        <button
          {...getToggleButtonProps()}
          className="ac-select-box-non-native__button"
          disabled={disabled}
        >
          {(selectedItem && selectedItem.label) || placeholder}
        </button>
        <input
          type="hidden"
          name={name}
          value={selectedItem && selectedItem.value}
        />
        <span
          {...getMenuProps({
            onMouseLeave: () => setHighlightedIndex(-1),
            onBlur
          })}
          className="ac-select-box-non-native__options-container"
        >
          <CSSTransition
            in={isOpen}
            timeout={150}
            mountOnEnter={true}
            unmountOnExit={true}
            onEntering={onOpening}
            onExited={onClosed}
            classNames="ac-select-box-non-native__options--transition"
          >
            <ul
              className="ac-select-box-non-native__options"
              ref={setListRef}
              style={{
                marginLeft: `${leftListOffset}px`
              }}
            >
              {options.map((option, index) => (
                <li
                  key={`${option.value}${index}`}
                  {...getItemProps({
                    item: option,
                    index,
                    onClick: event => {
                      // event.persist();
                      const newEvent = {
                        target: { value: option.value, name }
                      };
                      // event.target = { value: option.value, name } as EventTarget;
                      onChange(newEvent);
                    }
                  })}
                  className={clsx('ac-select-box-non-native__option', {
                    'ac-select-box-non-native__option--hover':
                      highlightedIndex === index,
                    'ac-select-box-non-native__option--selected':
                      selectedItem && selectedItem.value === option.value
                  })}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </CSSTransition>
        </span>
      </span>
    );
  }
);

/* SelectBoxNonNative.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
      hidden: PropTypes.bool,
    })
  ).isRequired,
}; */

export default SelectBoxNonNative;
