import { AutoCompleteData } from '../components/auto-complete/AutoComplete';

export const fetchAirports = async (searchValue: string) => {
  const url = `${process.env.REACT_APP_API_MIDDLEWARE_BASE}/api/v1.0/locations?term=${searchValue}&=nl-NL&location_types=airport&limit=10&active_only=true`;
  const result = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const json = await result.json();
  const airportResults: AutoCompleteData[] = json.locations.map(
    ({ id, name, slug, code, city }) => ({
      id,
      city: city.name,
      name,
      key: slug,
      code
    })
  );
  return airportResults;
};
