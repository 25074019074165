import { Action } from 'redux';
import { Currency } from '../../utils/constants';

export enum ValuesActionType {
  updateCurrency = '@@values/UPDATE_CURRENCY'
}

export interface updateCurrencyAction
  extends Action<ValuesActionType.updateCurrency> {
  payload: { currency: Currency };
}

export type ValueActions = updateCurrencyAction;
