import { ValueActions, ValuesActionType } from './valueActionTypes';
import { Currency } from '../../utils/constants';

export interface ValueState {
  currency: Currency;
}
const initialState: ValueState = {
  currency: Currency.EURO
};

const reducers = (
  state: ValueState = initialState,
  action: ValueActions
): ValueState => {
  switch (action.type) {
    case ValuesActionType.updateCurrency:
      return {
        ...state,
        currency: action.payload.currency
      };

    default:
      return state;
  }
};
export default reducers;
