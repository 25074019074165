import clsx from 'clsx';
import React, { FunctionComponent, memo, ReactElement } from 'react';
import './Icon.scss';

interface Props {
  children: ReactElement;
  className?: string;
  marginLeft?: boolean;
  marginRight?: boolean;
  smallMargin?: boolean;
  variant?: 'primary' | 'secondary' | 'error';
}

const Icon: FunctionComponent<Props> = ({
  className = '',
  children,
  marginLeft = false,
  marginRight = false,
  smallMargin = false,
  variant = 'primary'
}) => (
  <span
    className={clsx('icon', className, `icon--variant-${variant}`, {
      'icon--margin-left': marginLeft,
      'icon--margin-right': marginRight,
      'icon--small-margin': smallMargin
    })}
  >
    {children}
  </span>
);

export default memo(Icon);
