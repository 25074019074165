import clsx from 'clsx';
import Downshift from 'downshift';
import { Moment } from 'moment';
import React, { forwardRef, RefObject, useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Manager, Popper, Reference } from 'react-popper';
import ErrorMessage from '../error-message/ErrorMessage';
import './DateRange.scss';

interface Props {
  id: string;
  start?: Moment;
  end?: Moment;
  error?: string;
  touched?: boolean;
  onChange(start: string, end: string): void;
  onTouch(): void;
  placeholder?: string;
  fullWidth?: boolean;
}

const formatDates = (
  startDate?: Moment,
  endDate?: Moment
): string | undefined => {
  let value: string | undefined = undefined;
  if (startDate) {
    value = `${startDate.format('DD MMM `YY')} /`;
  }
  if (endDate) {
    value = value
      ? `${value} ${endDate.format('DD MMM `YY')}`
      : `/ ${endDate.format('DD MMM `YY')}`;
  }
  return value;
};

const DateRange = forwardRef<RefObject<HTMLSpanElement>, Props>(
  (
    {
      id,
      onChange,
      placeholder,
      fullWidth,
      start,
      end,
      error,
      touched,
      onTouch
    },
    ref
  ) => {
    const [inputFocused, setInputFocused] = useState<string>('startDate');
    const value = formatDates(start, end);
    const hasError = touched && error !== undefined;
    const classes = clsx('date-range', {
      'date-range--placeholder': placeholder && placeholder !== '',
      'date-range--full-width': fullWidth,
      'date-range--focused': !!value
    });
    return (
      <Downshift id="downshift-date-picker" onStateChange={onTouch}>
        {({ getToggleButtonProps, isOpen, ...props }) => {
          return (
            <span className={classes}>
              <Manager>
                <Reference>
                  {({ ref }) => (
                    <>
                      <span
                        {...getToggleButtonProps()}
                        ref={ref}
                        className="date-range__button"
                      >
                        {value || '  '}
                      </span>
                      {placeholder && (
                        <span className="date-range__placeholder">
                          {placeholder}
                        </span>
                      )}
                    </>
                  )}
                </Reference>
                {isOpen && (
                  <Popper placement="auto">
                    {({ ref, style, placement }) => (
                      <div
                        ref={ref}
                        style={style}
                        data-placement={placement}
                        className="date-range__menu"
                      >
                        <DayPickerRangeController
                          startDate={start}
                          endDate={end}
                          onDatesChange={({ startDate: start, endDate: end }) =>
                            onChange(start, end)
                          }
                          focusedInput={inputFocused}
                          onFocusChange={focusedInput =>
                            setInputFocused(focusedInput || 'startDate')
                          }
                        />
                      </div>
                    )}
                  </Popper>
                )}
              </Manager>
              {hasError && !isOpen && (
                <ErrorMessage withIcon>{error}</ErrorMessage>
              )}
            </span>
          );
        }}
      </Downshift>
    );
  }
);

export default DateRange;
