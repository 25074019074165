import React, { FunctionComponent } from 'react';
import './About.scss';
import Typography from '../../../components/typography/Typography';
import ResponsiveContainer from '../../../components/responsive-container/ResponsiveContainer';
import List from '../../../components/list/list';
import ListItem from '../../../components/list/list-item';
interface Props {}
const About: FunctionComponent<Props> = () => {
  return (
    <ResponsiveContainer className="about">
      <Typography type="h1" variant="secondary" marginBottom="lg">
        Bringing people closer together is our business.
      </Typography>

      <Typography marginBottom="lg">
        Sometimes, the people who are closest to you, live the furthest away.
        Findourtrip helps you find new places to meet, based on the lowest
        price.
      </Typography>

      <Typography marginBottom="xs">3 iconen:</Typography>
      <List listType="check" childSpacing="small" noMarginTop>
        <ListItem>
          Matching flights selected on price: Findourtrip lets you find matching
          flights for the best price.
        </ListItem>
        <ListItem>
          100+ destinations: In Europe alone, so plenty of possible places to
          meet each other.
        </ListItem>
        <ListItem>
          Kiwi.com guarantee: Cancelled or delayed flight? Our ticketing partner
          Kiwi has you covered.
        </ListItem>
      </List>

      <Typography marginBottom="lg">
        Findourtrip was founded in 2019, as a result of 2 years of endless Skype
        sessions to book a trip from Amsterdam and Zürich to “anywhere”. This
        made us think: Surely we are not the only ones in this situation. Now,
        we look forward to helping others living apart from their loved ones to
        organise their trip.
      </Typography>

      <Typography marginTop="lg">
        Findourtrip.com
        <br />
        *adres
        <br />
        KvK nummer
      </Typography>
    </ResponsiveContainer>
  );
};

export default About;
