import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';
import './list.scss';

interface ContextProps {
  childSpacing?: 'normal' | 'large' | 'small';
  lineHeight?: 'normal' | 'large' | 'small';
  variant?: 'primary' | 'secondary';
  listType?: 'bullet' | 'check';
}

interface Props extends ContextProps {
  children: ReactNode;
  className?: string;
  noMargin?: boolean;
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
}

export const ListContext = React.createContext<ContextProps>({
  childSpacing: 'normal',
  variant: 'primary',
  listType: 'bullet',
  lineHeight: 'normal'
});

const List: FunctionComponent<Props> = ({
  children,
  className = '',
  childSpacing = 'normal',
  lineHeight = 'normal',
  variant = 'primary',
  listType = 'bullet',
  noMargin = false,
  noMarginTop = false,
  noMarginBottom = false
}) => {
  const classes = clsx('list', className, {
    'list--no-margin': noMargin,
    'list--no-margin-top': noMarginTop,
    'list--no-margin-bottom': noMarginBottom,
    'list--list-type-check': listType === 'check'
  });

  return (
    <ul className={classes}>
      <ListContext.Provider
        value={{ childSpacing, variant, listType, lineHeight }}
      >
        {children}
      </ListContext.Provider>
    </ul>
  );
};

export default List;
