import React, { Component } from 'react';
import clsx from 'clsx';
import MarkerDistination from '../../assets/svg/airport-destination.svg';
import MarkerMuted from '../../assets/svg/airport-muted.svg';
import MarkerOrigin from '../../assets/svg/airport-origin.svg';

import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline
} from '@react-google-maps/api';

export default class extends Component {
  googleRef = undefined;
  mapRef = undefined;
  componentWillUnmount() {
    this.googleRef = null;
  }

  createBounds() {
    const { results, selected } = this.props;
    if (results && results.flights && this.googleRef) {
      const bounds = new this.googleRef.maps.LatLngBounds();
      if (selected) {
        results.origins.forEach(({ position }) => bounds.extend(position));
        bounds.extend(selected.destination);
      } else {
        results.flights.forEach(({ destination }) =>
          bounds.extend({ lat: destination.lat, lng: destination.lng })
        );
        results.origins.forEach(({ position }) => bounds.extend(position));
      }
      return bounds;
    }
    return undefined;
  }

  createMarkers() {
    const { selected, results, onMarker } = this.props;
    return (
      results &&
      results.flights &&
      results.flights.map(({ id, position, destination }) => {
        if (
          selected &&
          selected.destination &&
          selected.destination.id === String(id)
        ) {
          console.log('dest', id);
          return null;
        }
        return (
          <Marker
            key={id}
            position={{ lat: destination.lat, lng: destination.lng }}
            icon={MarkerMuted}
            clickable
            onClick={() => onMarker(id)}
          />
        );
      })
    );
  }

  createOriginMarkers() {
    const { results } = this.props;
    return (
      results &&
      results.origins &&
      results.origins.map(({ id, position }) => {
        return <Marker key={id} position={position} icon={MarkerOrigin} />;
      })
    );
  }

  createFlightPaths() {
    const { selected } = this.props;
    return (
      selected &&
      selected.routes.map(
        ({ routeTo: { latFrom, latTo, lngFrom, lngTo, id } }) => (
          <Polyline
            key={id}
            options={{
              geodesic: true,
              strokeColor: '#666',
              strokeOpacity: 1.0,
              strokeWeight: 1
            }}
            path={[
              { lat: latFrom, lng: lngFrom },
              {
                lat: latTo,
                lng: lngTo
              }
            ]}
          />
        )
      )
    );
  }

  render() {
    const { className, selected } = this.props;
    const classes = clsx('google-map', className);
    return (
      <LoadScript
        id="script-loader"
        onLoad={test => {
          this.googleRef = window.google;
        }}
        googleMapsApiKey="AIzaSyCXDVX5Q9V-tNZ_LTNenPBSjwIrqZol7Qc"
      >
        <GoogleMap
          onLoad={map => {
            this.mapRef = map;
          }}
          id="flights-map"
          mapContainerClassName={classes}
          options={{
            center: { lat: 49.4558405, lng: 11.2187303 },
            zoom: 3,
            mapTypeControl: false,
            streetViewControl: false,
            styles: [
              {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                  {
                    color: '#ededed'
                  },
                  {
                    visibility: 'on'
                  }
                ]
              },
              {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                  {
                    color: '#a3d1ee'
                  }
                ]
              }
            ]
          }}
        >
          {this.createMarkers()}
          {this.createOriginMarkers()}
          {selected && (
            <Marker
              animation={2}
              key={selected.destination.id}
              position={{
                lat: selected.destination.lat,
                lng: selected.destination.lng
              }}
              icon={MarkerDistination}
            />
          )}
          {this.createFlightPaths()}
        </GoogleMap>
      </LoadScript>
    );
  }
  componentDidUpdate() {
    const bounds = this.createBounds();
    if (this.mapRef && bounds) {
      this.mapRef.fitBounds(bounds);
    }
  }
}
