import fetch from 'isomorphic-fetch';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '..';
import { Dates, SearchOrigin } from '../../model/destination';
import { SEARCH_PARAMS } from '../../utils';
import {
  DestinationsActions,
  DestinationsActionType,
  DestinationsFailedAction,
  DestinationsFetchAction,
  DestinationsReceivedAction
} from './destinationsActionTypes';

export const searchDestinations = (
  origin1: SearchOrigin,
  origin2: SearchOrigin,
  dates: Dates
) => async (
  dispatch: ThunkDispatch<StoreState, undefined, DestinationsActions>,
  getState: () => StoreState
) => {
  const { values } = getState();
  const url = `${process.env.REACT_APP_API_MIDDLEWARE_BASE}/api/v1.0/flights/`;
  const startDate = dates.start!.format('DD/MM/YYYY');
  const endDate = dates.end!.format('DD/MM/YYYY');
  const query = `origins=${origin1.id},${origin2.id}&startDate=${startDate}&endDate=${endDate}&currency=${values.currency}`;
  dispatch(destinationsFetch(origin1, origin2, dates));
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ search: query })
  });
  if (response.status === 200) {
    const json = await response.json();
    await dispatch(destinationsReceived(json));
  } else {
    const json = await response.json();
    await dispatch(destinationsFailed(json));
  }
  const {
    destination: { search }
  } = getState();
  localStorage.setItem(SEARCH_PARAMS, JSON.stringify(search));
};

export const fetchStoredSearch = () => async (
  dispatch: ThunkDispatch<StoreState, undefined, DestinationsActions>,
  getState: () => StoreState
) => {
  const url = `${process.env.REACT_APP_API_MIDDLEWARE_BASE}/api/v1.0/flights/`;
  const {
    destination: { results, search }
  } = getState();

  if (results === undefined && search !== undefined) {
    const searchString = `origins=${search.origin1.id},${search.origin2.id}&startDate=${search.dates.start}&endDate=${search.dates.end}`;
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ search: searchString })
    });
    if (response.status === 200) {
      const json = await response.json();
      await dispatch(destinationsReceived(json));
    } else {
      const json = await response.json();
      dispatch(destinationsFailed(json));
    }
  }
};

export const destinationsFetch = (
  origin1,
  origin2,
  dates
): DestinationsFetchAction => {
  return {
    type: DestinationsActionType.fetch,
    payload: {
      origin1,
      origin2,
      dates
    }
  };
};

export const destinationsReceived = (data): DestinationsReceivedAction => ({
  type: DestinationsActionType.received,
  payload: {
    data
  }
});
export const destinationsFailed = ({ errorKey }): DestinationsFailedAction => ({
  type: DestinationsActionType.failed,
  payload: {
    errorKey
  }
});
