import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import Collapsible from '../../../../components/collapsible/collapsible';
import { Flight } from '../../../../model/flight';
import CollapsibleHeader from './collapsible-header/CollapsibleHeader';
import FlightDetails from './flight-details/FlightDetails';
import './FlightListItem.scss';

interface Props {
  selected?: string;
  flight: Flight;
  onChange: (expanded: boolean, id: string) => void;
}

const FlightListItem: FunctionComponent<Props> = ({
  selected,
  flight,
  onChange
}) => {
  const {
    id,
    combinedPrice,
    destination: {
      city,
      airport: { country },
      code
    },
    routes
  } = flight;
  const classes = clsx('flight-list-item', {
    'flight-list-item--open': selected === id
  });
  return (
    <Collapsible
      id={id}
      className={classes}
      label={
        <CollapsibleHeader
          code={code}
          city={city}
          country={country}
          combinedPrice={combinedPrice}
        />
      }
      expanded={selected === id}
      onChange={(id, expanded) => onChange(expanded, id)}
    >
      <div className="flight-list-item__panel-details">
        {routes.map((flights, index) => {
          return (
            <FlightDetails
              key={index}
              flights={flights}
              className="flight-list-item__detail "
            />
          );
        })}
      </div>
    </Collapsible>
  );
};

export default FlightListItem;
