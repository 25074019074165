import clsx from 'clsx';
import Downshift from 'downshift';
import React, { FocusEvent, FunctionComponent } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { throttle } from 'throttle-debounce';
import Input from '../input/Input';
import './AutoComplete.scss';
import Typography from '../typography/Typography';

interface Props {
  onChange(value: AutoCompleteData): void;
  onBlur(e: FocusEvent<any>): void;
  onInput(value: string): void;
  data?: AutoCompleteData[];
  placeholder?: string;
  error?: string;
  touched?: boolean;
  name?: string;
  className?: string;
  inputClassName?: string;
  initialSelected?: AutoCompleteData;
}

export interface AutoCompleteData {
  name: string;
  city: string;
  id: string;
}

const AutoComplete: FunctionComponent<Props> = ({
  onChange,
  onBlur,
  onInput,
  data,
  placeholder,
  error,
  touched,
  name,
  className,
  inputClassName,
  initialSelected
}) => {
  const onInputThrottled = throttle(500, onInput);
  return (
    <Downshift
      initialSelectedItem={initialSelected}
      onInputValueChange={onInputThrottled}
      onChange={(selection: AutoCompleteData) => {
        onChange(selection);
      }}
      itemToString={selected =>
        selected === null ? '' : `${selected.city} (${selected.id})`
      }
    >
      {({ getInputProps, getItemProps, isOpen }) => {
        return (
          <div className={clsx('auto-complete', className)}>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <Input
                    ref={ref}
                    className="auto-complete__input"
                    {...getInputProps()}
                    inputProps={{ className: inputClassName }}
                    name={name}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    error={error}
                    touched={touched}
                    noAutoComplete
                  ></Input>
                )}
              </Reference>
              {isOpen && data && data.length >= 1 && (
                <Popper placement="bottom">
                  {({ ref, style, placement }) => (
                    <div
                      ref={ref}
                      style={style}
                      data-placement={placement}
                      className="auto-complete__menu"
                    >
                      {data.slice(0, 8).map((suggestion, index) => (
                        <div
                          className="auto-complete__menu-item"
                          {...getItemProps({
                            item: suggestion
                          })}
                          key={suggestion.id}
                        >
                          <Typography type="body-large">
                            {suggestion.city} ({suggestion.id})
                          </Typography>
                          <Typography type="body-small-light">
                            {suggestion.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </Popper>
              )}
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
};

export default AutoComplete;
