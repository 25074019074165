import clsx from 'clsx';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Yup from 'yup';
import { Dates, SearchOrigin } from '../../model/destination';
import { StoreState } from '../../store';
import { searchDestinations } from '../../store/destinations/destinationActions';
import { SearchData } from '../../store/destinations/destinationReducer';
import { fetchAirports } from '../../utils/airport-autocomplete';
import { Currency } from '../../utils/constants';
import AutoComplete, { AutoCompleteData } from '../auto-complete/AutoComplete';
import IconButton from '../button/IconButton';
import DateRange from '../date-range/DateRange';
import Search from '../icons/Search';
import Typography from '../typography/Typography';
import './SearchBar.scss';

interface Props {
  className?: string;
  fetchOnChange?: boolean;
  searchData?: SearchData;
  searchDestinations(
    origin1: SearchOrigin,
    origin2: SearchOrigin,
    dates: Dates
  ): void;
  onSubmit?: () => void;
}

interface FormValues {
  origin1?: string;
  origin2?: string;
  dates: Dates;
}

const SignupSchema = Yup.object().shape({
  origin1: Yup.string().required('Required'),
  origin2: Yup.string().required('Required'),
  dates: Yup.object()
    .test((values: Dates) => values.start && values.end)
    .required('Required')
});

const SearchBar: FunctionComponent<Props> = ({
  className,
  fetchOnChange = false,
  searchDestinations,
  searchData,
  onSubmit = () => {}
}) => {
  const classes = clsx('search-bar', className);
  const [origin1Data, setOrigin1Data] = useState<
    AutoCompleteData[] | undefined
  >(undefined);
  const [origin1, setOrigin1] = useState<AutoCompleteData | undefined>(
    searchData && searchData.origin1
  );
  const [origin2Data, setOrigin2Data] = useState<
    AutoCompleteData[] | undefined
  >(undefined);
  const [origin2, setOrigin2] = useState<AutoCompleteData | undefined>(
    searchData && searchData.origin2
  );
  const submit = (values: FormValues) => {
    if (origin1 && origin2 && values.dates.start && values.dates.end) {
      searchDestinations({ ...origin1 }, { ...origin2 }, values.dates);
      onSubmit();
    }
  };
  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    handleBlur,
    setFieldTouched
  } = useFormik({
    initialValues: {
      origin1: searchData && searchData.origin1.name,
      origin2: searchData && searchData.origin2.name,
      dates: {
        start:
          searchData &&
          searchData.dates &&
          moment(searchData.dates.start, 'DD/MM/YYYY'),
        end:
          searchData &&
          searchData.dates &&
          moment(searchData.dates.end, 'DD/MM/YYYY')
      }
    },
    onSubmit: submit,
    validationSchema: SignupSchema
  });
  const currency = useSelector<StoreState, Currency>(
    state => state.values.currency
  );
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (
        fetchOnChange &&
        origin1 &&
        origin2 &&
        values.dates.start &&
        values.dates.end
      ) {
        searchDestinations({ ...origin1 }, { ...origin2 }, values.dates);
      }
    }
  }, [currency, origin1, fetchOnChange, origin2, values, searchDestinations]);

  return (
    <div className={classes}>
      <form onSubmit={handleSubmit} className="search-bar__form">
        <div className="search-bar__input-column">
          <Typography
            type="body-small"
            marginBottom="xs"
            className="search-bar__input-label"
          >
            Your origin
          </Typography>
          <AutoComplete
            name="origin1"
            placeholder="city/airport/airport code"
            onChange={value => {
              setOrigin1(value);
              setFieldValue('origin1', value.id);
              setOrigin1Data(undefined);
            }}
            onInput={async value => {
              const data = await fetchAirports(value);
              setOrigin1Data(data);
            }}
            initialSelected={searchData && searchData.origin1}
            data={origin1Data}
            error={errors && errors.origin1}
            touched={touched && touched.origin1}
            onBlur={handleBlur}
            className="search-bar__autocomplete-input"
            inputClassName="search-bar__autocomplete-input-field"
          />
        </div>
        <div className="search-bar__input-column">
          <Typography
            type="body-small"
            marginBottom="xs"
            className="search-bar__input-label"
          >
            Other origin
          </Typography>
          <AutoComplete
            name="origin2"
            placeholder="city/airport/airport code"
            onChange={value => {
              setOrigin2(value);
              setFieldValue('origin2', value.id);
              setOrigin1Data(undefined);
            }}
            onInput={async value => {
              const data = await fetchAirports(value);
              setOrigin2Data(data);
            }}
            initialSelected={searchData && searchData.origin2}
            data={origin2Data}
            error={errors && errors.origin2}
            touched={touched && touched.origin2}
            onBlur={handleBlur}
            className="search-bar__autocomplete-input"
            inputClassName="search-bar__autocomplete-input-field"
          />
        </div>
        <div className="search-bar__input-column">
          <Typography
            type="body-small"
            marginBottom="xs"
            className="search-bar__input-label"
          >
            Dates
          </Typography>
          <DateRange
            id="dates"
            placeholder="Choose dates"
            onChange={(start: string, end: string) =>
              setFieldValue('dates', { start, end })
            }
            onTouch={() => setFieldTouched('dates', true)}
            start={values.dates && values.dates.start && values.dates.start}
            end={values.dates && values.dates.start && values.dates.end}
            error={errors && errors.dates && String(errors.dates)}
            touched={touched && !!touched.dates}
          />
        </div>
        <IconButton type="submit" className="search-bar__submit">
          <Search />
        </IconButton>
      </form>
    </div>
  );
};
const mapStateToProps = (state: StoreState) => ({
  results: state.destination.results,
  searchData: state.destination.search
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, undefined, AnyAction>
) => ({
  searchDestinations: (
    origin1: SearchOrigin,
    origin2: SearchOrigin,
    dates: Dates
  ) => {
    dispatch(searchDestinations(origin1, origin2, dates));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
