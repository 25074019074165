import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { SEARCH_PARAMS } from '../utils';
import destinationReducer, {
  DestinationsState
} from './destinations/destinationReducer';
import valueReducer, { ValueState } from './values/valueReducer';

export const history = createBrowserHistory();

export interface StoreState {
  destination: DestinationsState;
  values: ValueState;
}

const rootReducer = combineReducers<StoreState>({
  destination: destinationReducer,
  values: valueReducer
});

let initialState = {};

const search = localStorage.getItem(SEARCH_PARAMS);
if (search) {
  initialState = { destination: { search: JSON.parse(search) } };
}

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
