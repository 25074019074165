import { useCallback, useEffect, useMemo, useState } from 'react';

type HookReturn = [
  IntersectionObserverEntry | undefined,
  { registerNode: (node: HTMLElement) => void; disconnect: () => void }
];

type Props = (
  root: Element | null | undefined,
  rootMargin: string,
  thresholds: number | number[]
) => HookReturn;
const useIntersectionObserver: Props = (root, rootMargin, threshold = 0) => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry | undefined>(
    undefined
  );
  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    });
  }, [root, rootMargin, threshold]);
  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  const registerNode = useCallback(
    (node: any) => {
      observer.disconnect();
      if (node) {
        observer.observe(node);
      }
    },
    [observer]
  );
  const disconnect = useCallback(() => {
    observer.disconnect();
  }, [observer]);

  const api = useMemo(
    () => ({
      registerNode,
      disconnect
    }),
    [disconnect, registerNode]
  );
  return [entry, api];
};

export default useIntersectionObserver;
