import React, { FunctionComponent } from 'react';
import ResponsiveContainer from '../../../components/responsive-container/ResponsiveContainer';
import Typography from '../../../components/typography/Typography';
import './FAQ.scss';
interface Props {}
const FAQ: FunctionComponent<Props> = () => {
  return (
    <ResponsiveContainer className="faq">
      <Typography type="h1" variant="secondary" marginBottom="lg">
        Need urgent assistance at the airport?
      </Typography>
      <Typography marginBottom="lg" type="h4" variant="secondary">
        Searching flights:
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        Why can I not set a destination?
      </Typography>
      <Typography marginBottom="xs">
        Findourtrip.com is a tool to explore various destinations from two
        origins based on the lowest price. Our aim is to show you which
        destinations are most fit for the two places you are departing from,
        which might be places you have not thought about yet.
      </Typography>
      <Typography marginBottom="lg">
        When you are satisfied with a destination and you want to book, you will
        be referred to our booking partner, Kiwi.com. There you will be able to
        tweak your departure date, arrival time, etc.
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        Why are there no results for my search?
      </Typography>
      <Typography marginBottom="lg">
        For some dates, there might not be matching tickets available from both
        origins to any location. You can try to experiment with changing the
        dates and the origin airports to get better results.
      </Typography>
      <Typography marginBottom="lg" type="h4" variant="secondary">
        Results:
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        One of my flights has a stopover. Can I choose to get only direct
        flights as a result?
      </Typography>
      <Typography marginBottom="lg">
        No. In the future we might add this option to our website. However, we
        have experienced that searching for direct flights dramatically
        decreases the amount of results.
      </Typography>
      <Typography marginBottom="lg" type="h4" variant="secondary">
        Booking flights:
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        Why was I redirected to another website?
      </Typography>
      <Typography marginBottom="lg">
        Findourtrip.com is a search engine. After you have found your matching
        flights, you can easily book with our partner Kiwi.com.
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        Why did you partner up with Kiwi.com?
      </Typography>
      <Typography marginBottom="lg">
        Kiwi.com is a reliable partner with 24/7 customer support and up-to-date
        data that fuels our search engine. We believe they provide us with the
        best results for finding common destinations for our customers.
      </Typography>
      <Typography marginBottom="xs" type="h4" variant="secondary">
        Why do I have to book each set of tickets seperately?
      </Typography>
      <Typography marginBottom="lg">
        Tickets cannot be booked as a package through our partner Kiwi.com.
      </Typography>
    </ResponsiveContainer>
  );
};

export default FAQ;
