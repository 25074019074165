import clsx from 'clsx';
import moment from 'moment';
import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plane } from '../../../../../assets/svg/plane.svg';
import {
  AirportRoute,
  FlightCarrier,
  FlightRoute
} from '../../../../../model/flight';
import './DetailRoute.scss';

interface Props {
  airport: AirportRoute;
  route: FlightRoute;
  carrier: FlightCarrier;
  className?: string;
  flyDuration: string;
  type: 'depart' | 'return';
}

const DetailRoute: FunctionComponent<Props> = ({
  airport,
  route,
  carrier,
  flyDuration,
  type,
  className = ''
}) => {
  const { t } = useTranslation();
  const stopOver =
    route.stopOvers === 0
      ? 'Direct'
      : t('stopover', { count: route.stopOvers });
  return (
    <div className={clsx('detail-route', className)}>
      <div className="detail-route__details">
        <span>{type}</span>
        <span>{moment.unix(route.dTime).format('DD MMM YYYY')}</span>
      </div>
      <div className="detail-route__flight-codes">
        <span>{route.flyFrom}</span>
        <Plane className="detail-route__icon" />
        <span>{route.flyTo}</span>
      </div>
      <div className="detail-route__airports">
        <span>{airport.from.name}</span>
        <span>{airport.to.name}</span>
      </div>
      <div className="detail-route__flight-carrier">
        <img
          src={`https://images.kiwi.com/airlines/64/${carrier.id}.png`}
          className="detail-route__flight-carrier-logo"
          alt={`carrier ${carrier.id}`}
        />
        <span>{carrier.name}</span>
        <span>{stopOver}</span>
      </div>
      <div className="detail-route__flight-times">
        <span>{moment.unix(route.dTime).format('HH:mm')}</span>
        <div className="detail-route__flight-duration">{flyDuration}</div>
        <span>{moment.unix(route.aTime).format('HH:mm')}</span>
      </div>
    </div>
  );
};

export default memo(DetailRoute);
