import React, { Component } from 'react';

import './page.scss';

class Page extends Component {
  render() {
    const { className, children } = this.props;
    let classes = ['page', className];
    return <div className={classes.join(' ')}>{children}</div>;
  }
}

export default Page;
