export enum Currency {
  POUNDS = 'GBP',
  EURO = 'EUR',
  US_DOLLAR = 'USD'
}
export enum CurrencySymbol {
  POUNDS = '£',
  EURO = '€',
  US_DOLLAR = '$'
}
