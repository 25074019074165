import clsx from 'clsx';
import React, { FunctionComponent, memo } from 'react';
import PrimaryButton from '../../../../../components/button/PrimaryButton';
import { Flights } from '../../../../../model/flight';
import DetailRoute from '../detail-route/DetailRoute';
import './FlightDetails.scss';
import Plane from '../../../../../components/icons/Plane';

interface Props {
  flights: Flights;
  className?: string;
}

const FlightDetails: FunctionComponent<Props> = ({
  flights,
  className = ''
}) => {
  return (
    <div className={clsx('flight-details', className)}>
      <div className="flight-details__routes">
        <DetailRoute
          type="depart"
          flyDuration={flights.flyDuration}
          airport={flights.airportTo}
          route={flights.routeTo}
          carrier={flights.carrierTo}
        />
        <DetailRoute
          type="return"
          flyDuration={flights.flyDuration}
          airport={flights.airportFrom}
          route={flights.routeFrom}
          carrier={flights.carrierFrom}
        />
      </div>
      <div className="flight-details__action">
        <span className="flight-details__price">€ {flights.price}</span>
        <PrimaryButton
          icon={{ right: <Plane className="flight-details__button-icon" /> }}
          className="flight-details__button"
        >
          GO!
        </PrimaryButton>
      </div>
    </div>
  );
};

export default memo(FlightDetails);
