import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
// import styles from './flight-list.styles';
import FlightListItem from '../flight-list-item/FlightListItem';
import './FlightList.scss';

interface Props {
  results: any;
  className?: string;
  selected?: string;
  onSelected: (selected?: string) => void;
}

const FlightList: FunctionComponent<Props> = ({
  results,
  className = '',
  onSelected,
  selected
}) => {
  return (
    <div className={clsx('flight-list', className)}>
      {results &&
        results.flights &&
        results.flights.map(flight => {
          return (
            <FlightListItem
              flight={flight}
              selected={selected}
              key={flight.bookingToken}
              onChange={(expanded, id) => onSelected(expanded ? id : undefined)}
            />
          );
        })}
    </div>
  );
};
export default FlightList;
