import i18next from 'i18next';
import React, { FunctionComponent } from 'react';
import { hot } from 'react-hot-loader/root';
import { initReactI18next } from 'react-i18next';
import Pages from '../../containers/pages/Pages';
import './app.scss';

i18next
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'en',
    debug: false,
    resources: {
      en: {
        translation: {
          nights: '{{count}} night',
          nights_plural: '{{count}} nights',
          stopover: '{{count}} stopover',
          stopover_plural: '{{count}} stopovers'
        }
      }
    }
  });

interface Props {}

const App: FunctionComponent<Props> = () => <Pages key="pages" />;

export default hot(App);
