import clsx from 'clsx';
import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';
import './collapsible.scss';

interface Props {
  label: ReactNode;
  id: string;
  error?: boolean;
  onChange: (id: string, expanded: boolean) => void;
  expanded: boolean;
  children: ReactNode;
  className?: string;
}

export const CollapsibleContext = createContext({ expanded: false });

const Collapsible: FunctionComponent<Props> = ({
  id,
  children,
  onChange,
  className = '',
  label,

  expanded = false,

  error = false
}) => {
  const containerContent = useRef<HTMLDivElement>(null);

  const [height, setContentHeight] = useState<number | undefined>(undefined);
  const [isExpanded, setExpandState] = useState<boolean>(false);

  const onInnerChange = useCallback(() => {
    onChange(id, !expanded);
  }, [expanded, onChange, id]);

  const setHeight = () => {
    if (containerContent.current) {
      setContentHeight(containerContent.current.clientHeight);
    }
  };

  const resetheight = () => {
    setContentHeight(undefined);
  };

  const onClosed = () => {
    setExpandState(false);
  };

  const onOpening = useCallback(async () => {
    await setExpandState(true);
    setHeight();
  }, []);

  const classes = clsx('collapsible', className, {
    'collapsible--open': expanded,
    'collapsible--error': error
  });

  children = isExpanded ? children : null;

  return useMemo(() => {
    return (
      <CollapsibleContext.Provider value={{ expanded }}>
        <div className={classes} id={id}>
          <div className="collapsible__header" onClick={onInnerChange}>
            {label}
          </div>
          <CSSTransition
            in={expanded}
            timeout={250}
            classNames="collapsible__container--transition"
            mountOnEnter={true}
            unmountOnExit={true}
            onEntering={onOpening}
            onEntered={resetheight}
            onExit={setHeight}
            onExiting={resetheight}
            onExited={onClosed}
          >
            <div className="collapsible__container" style={{ height }}>
              <div
                className="collapsible__container-content"
                ref={containerContent}
              >
                {children}
              </div>
            </div>
          </CSSTransition>
        </div>
      </CollapsibleContext.Provider>
    );
  }, [
    expanded,
    label,
    id,
    height,
    children,
    classes,
    onInnerChange,
    onOpening
  ]);
};

export default Collapsible;
