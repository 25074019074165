import clsx from 'clsx';
import React, { forwardRef, useMemo, useState } from 'react';
import { SelectOption, SelectProps } from './SelectBox';
import './SelectBoxNative.scss';

const SelectBoxNative = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const {
      className,
      options,
      placeholder,
      id,
      name,
      value,
      disabled = false,
      transparent = false,
      onChange = (event: any) => {},
      onBlur = () => {}
    } = props;

    const [focused, updateFocus] = useState(false);
    const classes = clsx('ac-select-box-native', className, {
      'ac-select-box-native--has-selection': value !== undefined,
      'ac-select-box-native--transparent': transparent,
      'ac-select-box-native--focused': focused
    });

    const selectedOption = useMemo<SelectOption | undefined>(() => {
      if (value) {
        return options.reduce<SelectOption | undefined>((acc, option) => {
          if (option.value === value) {
            return option;
          }
          return acc;
        }, undefined);
      }
      return undefined;
    }, [value, options]);
    return (
      <label htmlFor={id} className={classes}>
        <span className="ac-select-box-native__value">
          {selectedOption === undefined ? placeholder : selectedOption.label}
        </span>
        <select
          ref={ref}
          id={id}
          name={name}
          disabled={disabled}
          onChange={onChange}
          onBlur={event => {
            updateFocus(false);
            onBlur(event);
          }}
          onFocus={() => updateFocus(true)}
          value={value}
          className="ac-select-box-native__select"
        >
          {!value && <option></option>}
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </label>
    );
  }
);

export default SelectBoxNative;
