import clsx from 'clsx';
import React, {
  cloneElement,
  FunctionComponent,
  memo,
  ReactElement
} from 'react';
import Button, { ButtonProps } from './Button';
import './button.scss';

interface IconProps {
  left?: ReactElement;
  right?: ReactElement;
}

export interface ButtonWithIconProps extends ButtonProps {
  icon?: IconProps;
}

const ButtonWithIcon: FunctionComponent<ButtonWithIconProps> = ({
  children,
  className = '',
  icon = undefined,
  ...props
}) => {
  let classes = clsx(className);

  return (
    <Button className={classes} {...props}>
      {icon && icon.left && addClassesToIcon(icon.left, 'left')}
      {children}
      {icon && icon.right && addClassesToIcon(icon.right, 'right')}
    </Button>
  );
};
const addClassesToIcon = (icon: ReactElement, align: 'left' | 'right') => {
  if (icon) {
    return cloneElement(icon, {
      className: clsx(
        icon.props.className,
        'button__icon',
        `button__icon--${align}`
      )
    });
  }
  return null;
};

export default memo(ButtonWithIcon);
