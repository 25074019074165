import { Action } from 'redux';
import { SearchOrigin, Dates } from '../../model/destination';

export enum DestinationsActionType {
  received = '@@destination/RECEIVED',
  failed = '@@destination/FAILED',
  fetch = '@@destination/FETCH'
}

export interface DestinationsReceivedAction
  extends Action<DestinationsActionType.received> {
  payload: { data: any };
}
export interface DestinationsFailedAction
  extends Action<DestinationsActionType.failed> {
  payload: { errorKey: string };
}

export interface DestinationsFetchAction
  extends Action<DestinationsActionType.fetch> {
  payload: { origin1: SearchOrigin; origin2: SearchOrigin; dates: Dates };
}

export type DestinationsActions =
  | DestinationsReceivedAction
  | DestinationsFailedAction
  | DestinationsFetchAction;
